import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'sb-spinner',
  template: ` <p-progressSpinner
    class="flex"
    [style]="{ color: color }"
    [styleClass]="styleClass"
    [strokeWidth]="strokeWidth"
    [ngClass]="containerClasses"
  ></p-progressSpinner>`,
  imports: [NgClass, ProgressSpinnerModule],
  standalone: true,
})
export class SbSpinnerComponent implements OnInit {
  @Input()
  public containerClasses: string[] = [];
  @Input()
  public isWhite?: boolean = false;

  @Input()
  public isBlack?: boolean = false;

  @Input()
  public color: string;

  @Input()
  public styleClass: string;

  @Input()
  public strokeWidth = '2';

  public ngOnInit(): void {
    if (this.isBlack) {
      this.containerClasses.push('spinner-black');
    }
    if (this.isWhite) {
      this.containerClasses.push('spinner-white');
    }
  }
}
